/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.12.2-v202503122320-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface UserOutputV1 {
    /**
     * The access key validity duration, in minutes, to override the 'Authentication/AccessKey/ValidityWithoutUserLogin' configuration. After the last user login, authentication is allowed with an access key for the period specified here. A value of 0 will effectively disable access keys for this user.
     */
    "accessKeyValidityDuration"?: number;
    /**
     * The ISO 8601 date of when the user was provisioned for an agent (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "agentProvisioningAt"?: string;
    /**
     * The source IP or CIDR range from where the provisioned agent can connect using the user
     */
    "agentProvisioningSourceAddress"?: string;
    /**
     * The agent provisioning status.
     */
    "agentProvisioningStatus"?: string;
    /**
     * (DEPRECATED) Capabilities that the user has been granted
     */
    "capabilities"?: CapabilitiesEnum[];
    /**
     * Capabilities that the user has been granted, including where the capability was inherited from (if applicable)
     */
    "capabilityGrants"?: Array<models.CapabilityInheritanceOutputV1>;
    /**
     * The ISO 8601 date of when the user was created (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "createdAt"?: string;
    /**
     * The name of the data source (authentication directory) containing the user
     */
    "datasourceName"?: string;
    /**
     * Clarifying information or other plain language description of this item
     */
    "description"?: string;
    "effectiveAccessKeyValidityDuration"?: models.ScalarValueOutputV1;
    "effectivePermissions"?: models.PermissionsV1;
    "effectiveSessionDuration"?: models.ScalarValueOutputV1;
    /**
     * The email address of the user
     */
    "email"?: string;
    /**
     * The first name of the user
     */
    "firstName"?: string;
    /**
     * Enabled and unarchived groups the user belongs to
     */
    "groups"?: Array<models.IdentityPreviewV1>;
    /**
     * The ID that can be used to interact with the item
     */
    "id": string;
    /**
     * Whether the user is actively using Seeq
     */
    "isActive"?: boolean;
    /**
     * Whether the user has administrator capabilities in Seeq
     */
    "isAdmin"?: boolean;
    /**
     * Whether item is archived
     */
    "isArchived"?: boolean;
    /**
     * Whether the user is enabled
     */
    "isEnabled"?: boolean;
    /**
     * Whether the user is a manager or not
     */
    "isManager"?: boolean;
    /**
     * Whether the user's password may be updated
     */
    "isPasswordSettable"?: boolean;
    /**
     * Whether item is redacted
     */
    "isRedacted"?: boolean;
    /**
     * The ISO 8601 date of when the user last logged in (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "lastLoginAt"?: string;
    /**
     * The last name of the user
     */
    "lastName"?: string;
    /**
     * The ISO 8601 date of when the user's password last changed in (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "lastPasswordChangedAt"?: string;
    "manager"?: models.IdentityPreviewV1;
    /**
     * The maximum simultaneous API requests allowed by this user. Overrides the global Threads/HttpServer/MaxRequestsPerUser configuration.
     */
    "maxRequests"?: number;
    /**
     * The human readable name
     */
    "name": string;
    /**
     * User's password expiry status to determine if user is required to change current password.
     */
    "passwordExpiryStatus"?: PasswordExpiryStatusEnum;
    /**
     * Determines if this user is required to change their password on their next successful log in.
     */
    "requirePasswordResetOnNextLogin"?: boolean;
    /**
     * The user's role.
     */
    "role"?: string;
    /**
     * The session duration, in minutes, to override the 'Authentication/DefaultSessionDuration' configuration. This controls the maximum period of inactivity before the user must authenticate again.
     */
    "sessionDuration"?: number;
    /**
     * The Site a user is assigned to.
     */
    "site"?: string;
    /**
     * A plain language status message with information about any issues that may have been encountered during an operation
     */
    "statusMessage"?: string;
    /**
     * The Workstep ID that is used as the template for any new worksheets created by this user.
     */
    "templateWorkstepId"?: string;
    /**
     * The item's translation key, if any
     */
    "translationKey"?: string;
    /**
     * The type of the item
     */
    "type": string;
    /**
     * The username of the user
     */
    "username"?: string;
    /**
     * The workbench configuration of the user
     */
    "workbench"?: string;
}

export enum CapabilitiesEnum {
    Admins = 'Admins' as any,
    AnalyticsAdministrationCapability = 'AnalyticsAdministrationCapability' as any,
    AuditTrailCapability = 'AuditTrailCapability' as any,
    UserAdministrationCapability = 'UserAdministrationCapability' as any,
    DatasourceAdministrationCapability = 'DatasourceAdministrationCapability' as any,
    LogViewerCapability = 'LogViewerCapability' as any
}
export enum PasswordExpiryStatusEnum {
    CURRENT = 'CURRENT' as any,
    INGRACEPERIOD = 'IN_GRACE_PERIOD' as any,
    EXPIRED = 'EXPIRED' as any
}
